import React from 'react'
import { BsLinkedin, BsGithub } from 'react-icons/bs';

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/arnold-schiopu-3288a41a6/' target="_blank" rel="noreferrer"><BsLinkedin/></a>
        <a href='https://github.com/bsarnii' target="_blank" rel="noreferrer" ><BsGithub/></a>

    </div>
  )
}

export default HeaderSocials